export const parseInvoiceQrCode = (cont) => {
  // console.log('解析增值税发票二维码内容..', cont);
  // 增值税电子普通发票:01,00,00152846,011001500111,3,20170414,47752652920636820604,7983
  // 增值税电子普通发票（通行费）:01,10,013001700112,08570096,42.54,20180116,12907514978959073016,15BD,
  // 增值税专用发票:01,01,3400161130,00640203,99099.10,20160919,,C0DB
  // 增值税普通发票:01,04,032001800105,27751825,100.00,20180704,01936508246467520270,3C4F,
  // 01，01，4403204130，18744140，709.43，20210423，，5627，
  // 01,01,3400161130,00640203,
  // 01,04,032001800105,27751825
  // 00152846,011001500111
  // 013001700112,08570096
  // 数电发票-电子发票（增值税专用发票）:01,31, ,23442000000005501790,9599.42,20230114, ,4411
  // 数电发票-电子发票（普通发票）-旅客运输服务:01,02,,24117000000302245568,117.29,2024-06-19,,
  // 数电发票-电子发票（普通发票）:01,32,,24112000000001853247,480.00,20240118,,864E
  // 数电发票-电子发票（普通发票）-旅客运输服务:01,02,,24117000000281905460,114.17,2024-06-13,,
  // 数电发票-纸质普票（校验码=数电票号后六位）:01,32,011002100304,01012125,7800.00,20240511,334869,4A34,
  // 数电发票-纸质普票（校验码=数电票号前六位）:01,,011002100204,64885976,2100.71,20240617,241120,83B3,
  let res = doParseInvoiceQrCode(cont);
  if (res.success) {
    const invoice = res.invoice;
    if (
      invoice.invoiceType == "FullEleSpecInvoice" &&
      invoice.invoiceCode != ""
    ) {
      invoice.invoiceType = "FullElePaperSpecInvoice";
    }

    if (
      invoice.invoiceType == "FullEleGenerInvoice" &&
      invoice.invoiceCode != ""
    ) {
      invoice.invoiceType = "FullElePaperGenerInvoice";
    }
  }

  return res;

  function doParseInvoiceQrCode(cont) {
    let res = {
      content: cont,
      handled: cont,
      invoice: {
        invoiceCode: null,
        invoiceNo: null,
        eInvoiceNo: null,
        invoiceTime: null,
        amount: null,
        checkCode: null,
        verifyCode: null,
        invoiceType: null,
      },
      success: true,
    };
    try {
      cont = cont || "";
      //处理原文
      cont = cont.replace(/，/g, ","); //替换中文逗号
      cont = cont.replace(/ /g, ""); //剔除多余空格
      cont = cont.replace(/[\r\n]/g, ""); //剔除多余回车
      res.handled = cont;

      let invoice = res.invoice;
      let itype = "Unknow";

      let values, prop, props;
      let dmReg = /\d{12}|\d{10}/;
      let hmReg = /\d{8}/;
      let sdhmReg = /\d{20}/;
      let dmExtReg = /(\d{12}|\d{10})/;
      let hmExtReg = /(\d{8})/;
      values = cont.split(",");

      if (values.length > 1) {
        switch (values[1]) {
          case "14":
            itype = "EleInvoice";
            break;
          case "11":
            itype = "VolInvoice";
            break;
          case "02":
            itype = "FullEleGenerInvoice";
            break;
          case "03":
            itype = "VehInvoice";
            break;
          case "00":
            itype = "EleInvoice";
            break;
          case "04":
            itype = "GenerInvoice";
            break;
          case "01":
            itype = "SpecInvoice";
            break;
          case "10":
            itype = "EleInvoice";
            break;
          case "20":
            itype = "EleSpecInvoice";
            break;
          case "31":
            itype = "FullEleSpecInvoice";
            break;
          case "32":
            itype = "FullEleGenerInvoice";
            break;
          case "15":
          default:
            itype = "GenerInvoice";
            break;
        }
      }
      //发票类型
      invoice.invoiceType = itype;

      //策略一
      if (values.length > 2 && values.length < 4) {
        prop = values[1];
        if (dmReg.test(prop)) {
          invoice.invoiceCode = values[1];
          invoice.invoiceNo = values[2];
        }
        prop = values[2];
        if (dmReg.test(prop)) {
          invoice.invoiceCode = values[2];
          invoice.invoiceNo = values[1];
        }
        //发票金额
        let amountString = values[4];
        if (amountString) {
          amountString = amountString.replace(/[^\d.]/g, "");
          if (
            ["FullEleSpecInvoice", "FullEleGenerInvoice"].indexOf(itype) !=
              -1 &&
            invoice.invoiceCode == ""
          ) {
            invoice.sumAmount = parseFloat(amountString).toFixed(2);
          } else {
            invoice.amount = parseFloat(amountString).toFixed(2);
          }
        }

        //开票日期
        let dateStr = values[5];
        if (dateStr && dateStr.indexOf("-") != -1) {
          invoice.invoiceTime = dateStr;
        } else {
          let dateArr = [];
          if (dateStr) {
            dateArr.push(dateStr.substring(0, 4));
            dateArr.push(dateStr.substring(4, 6));
            dateArr.push(dateStr.substring(6, 8));
            invoice.invoiceTime = dateArr.join("-");
          }
        }

        //校验码后六位
        let vcode = values[6];
        if (vcode) {
          let vclen = vcode.length;
          if (vclen > 6) {
            invoice.checkCode = vcode;
            invoice.verifyCode = vcode.substring(vclen - 6, vclen);
          } else {
            invoice.checkCode = vcode;
            invoice.verifyCode = vcode;
          }
        }
        if (
          (sdhmReg.test(invoice.invoiceNo) && invoice.invoiceCode == "") ||
          (dmReg.test(invoice.invoiceCode) && hmReg.test(invoice.invoiceNo))
        ) {
          res.success = true;
          if (
            ["FullEleSpecInvoice", "FullEleGenerInvoice"].indexOf(itype) != -1
          ) {
            if (invoice.invoiceCode != "") {
              invoice.eInvoiceNo = "";
              if (itype == "FullEleSpecInvoice") {
                invoice.invoiceType = "FullElePaperSpecInvoice";
              }
              if (itype == "FullEleGenerInvoice") {
                invoice.invoiceType = "FullElePaperGenerInvoice";
              }
            } else {
              invoice.eInvoiceNo = invoice.invoiceNo;
              invoice.invoiceNo = "";
              invoice.invoiceCode = "";
            }
          }
          return res;
        }
      }

      //策略二
      if (values.length > 3) {
        prop = values[2];
        if (dmReg.test(prop)) {
          invoice.invoiceCode = values[2];
          invoice.invoiceNo = values[3];
        }
        if (sdhmReg.test(prop)) {
          invoice.invoiceCode = "";
          invoice.invoiceNo = values[2];
        }
        prop = values[3];
        if (dmReg.test(prop)) {
          invoice.invoiceCode = values[3];
          invoice.invoiceNo = values[2];
        }
        if (sdhmReg.test(prop)) {
          invoice.invoiceCode = "";
          invoice.invoiceNo = values[3];
        }
        //发票金额
        let amountString = values[4];
        if (amountString) {
          amountString = amountString.replace(/[^\d.]/g, "");
          if (
            ["FullEleSpecInvoice", "FullEleGenerInvoice"].indexOf(itype) !=
              -1 &&
            invoice.invoiceCode == ""
          ) {
            invoice.sumAmount = parseFloat(amountString).toFixed(2);
          } else {
            invoice.amount = parseFloat(amountString).toFixed(2);
          }
        }

        //开票日期
        let dateStr = values[5];
        if (dateStr && dateStr.indexOf("-") != -1) {
          invoice.invoiceTime = dateStr;
        } else {
          let dateArr = [];
          if (dateStr) {
            dateArr.push(dateStr.substring(0, 4));
            dateArr.push(dateStr.substring(4, 6));
            dateArr.push(dateStr.substring(6, 8));
            invoice.invoiceTime = dateArr.join("-");
          }
        }

        //校验码后六位
        let vcode = values[6];
        if (vcode) {
          let vclen = vcode.length;
          if (vclen > 6) {
            invoice.checkCode = vcode;
            invoice.verifyCode = vcode.substring(vclen - 6, vclen);
          } else {
            invoice.checkCode = vcode;
            invoice.verifyCode = vcode;
          }
        }
        if (
          (sdhmReg.test(invoice.invoiceNo) && invoice.invoiceCode == "") ||
          (dmReg.test(invoice.invoiceCode) && hmReg.test(invoice.invoiceNo))
        ) {
          res.success = true;
          if (
            ["FullEleSpecInvoice", "FullEleGenerInvoice"].indexOf(itype) != -1
          ) {
            if (invoice.invoiceCode != "") {
              invoice.eInvoiceNo = "";
              if (itype == "FullEleSpecInvoice") {
                invoice.invoiceType = "FullElePaperSpecInvoice";
              }
              if (itype == "FullEleGenerInvoice") {
                invoice.invoiceType = "FullElePaperGenerInvoice";
              }
            } else {
              invoice.eInvoiceNo = invoice.invoiceNo;
              invoice.invoiceNo = "";
              invoice.invoiceCode = "";
            }
          }
          return res;
        }
      }

      if (values.length == 1 && values[0] != "" && values[0].length == 20) {
        invoice.invoiceCode = "";
        invoice.invoiceNo = values[0];
        invoice.invoiceType = "FullEleSpecInvoice";
        res.success = true;
        if (
          ["FullEleSpecInvoice", "FullEleGenerInvoice"].indexOf(itype) != -1
        ) {
          if (invoice.invoiceCode != "") {
            invoice.eInvoiceNo = "";
            if (itype == "FullEleSpecInvoice") {
              invoice.invoiceType = "FullElePaperSpecInvoice";
            }
            if (itype == "FullEleGenerInvoice") {
              invoice.invoiceType = "FullElePaperGenerInvoice";
            }
          } else {
            invoice.eInvoiceNo = invoice.invoiceNo;
            invoice.invoiceNo = "";
            invoice.invoiceCode = "";
          }
        }
        return res;
      }

      //策略三
      //发票代码
      props = dmExtReg.exec(cont);
      if (props) {
        invoice.invoiceCode = props[1];
      }

      //发票号码
      if (invoice.invoiceCode) {
        cont = cont.replace(invoice.invoiceCode, "");
      }
      props = hmExtReg.exec(cont);
      if (props) {
        invoice.invoiceNo = props[1];
      }

      //发票金额
      let amountString = values[4];
      if (amountString) {
        amountString = amountString.replace(/[^\d.]/g, "");
        if (
          ["FullEleSpecInvoice", "FullEleGenerInvoice"].indexOf(itype) != -1 &&
          invoice.invoiceCode == ""
        ) {
          invoice.sumAmount = parseFloat(amountString).toFixed(2);
        } else {
          invoice.amount = parseFloat(amountString).toFixed(2);
        }
      }

      //开票日期
      let dateStr = values[5];
      if (dateStr && dateStr.indexOf("-") != -1) {
        invoice.invoiceTime = dateStr;
      } else {
        let dateArr = [];
        if (dateStr) {
          dateArr.push(dateStr.substring(0, 4));
          dateArr.push(dateStr.substring(4, 6));
          dateArr.push(dateStr.substring(6, 8));
          invoice.invoiceTime = dateArr.join("-");
        }
      }

      //校验码后六位
      let vcode = values[6];
      if (vcode) {
        let vclen = vcode.length;
        if (vclen > 6) {
          invoice.checkCode = vcode;
          invoice.verifyCode = vcode.substring(vclen - 6, vclen);
        } else {
          invoice.checkCode = vcode;
          invoice.verifyCode = vcode;
        }
      }

      if (
        (sdhmReg.test(invoice.invoiceNo) && invoice.invoiceCode == "") ||
        (dmReg.test(invoice.invoiceCode) && hmReg.test(invoice.invoiceNo))
      ) {
        res.success = true;
        if (
          ["FullEleSpecInvoice", "FullEleGenerInvoice"].indexOf(itype) != -1
        ) {
          if (invoice.invoiceCode != "") {
            invoice.eInvoiceNo = "";
            if (itype == "FullEleSpecInvoice") {
              invoice.invoiceType = "FullElePaperSpecInvoice";
            }
            if (itype == "FullEleGenerInvoice") {
              invoice.invoiceType = "FullElePaperGenerInvoice";
            }
          } else {
            invoice.eInvoiceNo = invoice.invoiceNo;
            invoice.invoiceNo = "";
            invoice.invoiceCode = "";
          }
        }
        return res;
      }
    } catch (err) {
      console.log("解析发票二维码出错..", err);
      res.success = false;
    }
    return res;
  }
};
